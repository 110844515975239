import React from 'react';
import { Box, CircularProgress, Grid, Skeleton } from '@mui/material';

const Loader = ({number}) => {
  const skeletonItems = Array.from(new Array(12)); // 8 skeleton items to fill the screen

  return (
    <Box minHeight="100vh" p={2}>
      <Grid container spacing={3}>
        {skeletonItems.map((_, index) => (
          <Grid item xs={12} sm={6} md={number === 2 ? 6 : 2} lg={number === 2 ? 6: 3} key={index}>
            {/* Skeleton for a video thumbnail */}
            <Skeleton
              variant="rectangular"
              width="100%"
              height={200}
              animation="wave"
              sx={{ bgcolor: '#635f5f', borderRadius:2}}
            />
            {/* Skeleton for title text */}
            <Skeleton
              variant="text"
              width="80%"
              animation="wave"
              sx={{ bgcolor: '#635f5f', mt: 1 }}
            />
            {/* Skeleton for subtitle text */}
            <Skeleton
              variant="text"
              width="60%"
              animation="wave"
              sx={{ bgcolor: '#635f5f' }}
            />
          </Grid>
        ))}
      </Grid>
      {/* <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress sx={{ color: 'grey.500' }} />
      </Box> */}
    </Box>
  );
};

export default Loader;
