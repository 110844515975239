import React from "react";
import { Stack } from "@mui/material";
import { motion } from 'framer-motion';
import FaceRetouchingNaturalIcon from "@mui/icons-material/FaceRetouchingNatural";
import LiveTvIcon from '@mui/icons-material/LiveTv';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const categoriesList = [
  { _id: "1", label: "Top", icon: <FaceRetouchingNaturalIcon /> },
  { _id: "5", label: "Streaming", icon: <LiveTvIcon />, blinking: true },
  { _id: "2", label: "New", icon: <FaceRetouchingNaturalIcon /> },
  { _id: "3", label: "Free Video", icon: <FaceRetouchingNaturalIcon /> },
  { _id: "4", label: "Vip Hot", icon: <FaceRetouchingNaturalIcon /> }
];

const Categories = ({ selectedCategory, setSelectedCategory, selectedCategoryId, setSelectedCategoryId }) => {
  const { categories: categoryData } = useSelector((state) => state.category);
  const navigate = useNavigate(); // Initialize navigate
  const handleCategoryClick = (category) => {
    setSelectedCategory(category.label);
    setSelectedCategoryId(category._id);
    navigate(`/${category.label.toLowerCase()}`);
  };

  const renderCategoryButton = (category) => (
    <button
      className="category-btn"
      onClick={() => handleCategoryClick(category)}
      style={{
        background: category._id === selectedCategoryId ? "#F79A24" : "transparent",
        color: "white",
      }}
      key={category._id}
    >
      <span style={{ color: category._id === selectedCategoryId ? "white" : "red", marginRight: "15px" }}>
        {category.icon}
      </span>
      <span style={{ opacity: category._id === selectedCategoryId ? "1" : "0.8", marginRight: "10px" }}>
        {category.label}
      </span>
      {category.blinking && (
        <span style={{ display: 'flex', alignItems: 'center', marginLeft: "8px" }}>
          <motion.span
            animate={{ opacity: [0, 1, 0] }} // Blinking animation
            transition={{ repeat: Infinity, duration: 1 }} // Loop the animation
            style={{
              width: "10px",
              height: "10px",
              backgroundColor: "red",
              borderRadius: "50%",
              display: "inline-block",
            }}
          />
        </span>
      )}
    </button>
  );

  return (
    <Stack
      direction="row"
      sx={{
        overflowY: "auto",
        height: { sx: "auto", md: "95%" },
        flexDirection: { md: "column" },
      }}
    >
      {categoriesList.map(renderCategoryButton)}
      {categoryData.map((category) => renderCategoryButton({ ...category,id:category._id, label: category.name,icon: <FaceRetouchingNaturalIcon /> }))}
    </Stack>
  );
};

export default Categories;
