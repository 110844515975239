import { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { useParams } from "react-router-dom";

// import { fetchFromAPI } from "../utils/fetchFromAPI";
import { useDispatch, useSelector } from "react-redux";
import { fetchVideosByTags } from "./../api/video/videoSlice";
import Videos from "../Videos";

const SearchFeedPage = () => {
  // const [videos, setVideos] = useState(null);
  const { searchTerm } = useParams();

  // useEffect(() => {
  //   fetchFromAPI(`search?part=snippet&q=${searchTerm}`)
  //     .then((data) => setVideos(data.items))
  // }, [searchTerm]);

  const dispatch = useDispatch();
  const { videoTag, loadingTag, errorTag } = useSelector((state) => state.videos);

  useEffect(() => {
    console.log("search", searchTerm);
    dispatch(fetchVideosByTags({ keyword:searchTerm, page: 1, limit: 10 }));
  }, [searchTerm]);

  console.log("videos.documents",videoTag?.documents);
  return (
    <Box p={2} minHeight="95vh">
      <Typography variant="h4" fontWeight={900} color="white" mb={3} ml={{ sm: "100px" }}>
        Search Results for <span style={{ color: "#F79A24" }}>{searchTerm}</span> videos
      </Typography>
      <Box display="flex">
        <Box sx={{ mr: { sm: '100px' } }} />
        {videoTag?.documents && (<Videos videos={videoTag?.documents} />)}
        
      </Box>
    </Box>
  );
};

export default SearchFeedPage;
