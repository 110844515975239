import React from "react";
import { Box } from "@mui/material";
import UploadVideoCard from './UploadVideoCard';

const UploadVideo = () => {


    return (
        <Box minHeight="95vh">
            <Box width={"100%"}>
                <UploadVideoCard />
            </Box>
        </Box>
    );
};

export default UploadVideo;