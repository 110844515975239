import React, { createContext, useState, useContext } from "react";

const CategoryContext = createContext();

export const useCategory = () => useContext(CategoryContext);

export const CategoryProvider = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState("Top");
  const [selectedCategoryId, setSelectedCategoryId] = useState("1");

  return (
    <CategoryContext.Provider
      value={{ selectedCategory, setSelectedCategory, selectedCategoryId, setSelectedCategoryId }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
