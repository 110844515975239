import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box, Chip, Stack } from "@mui/material";

import { Videos, ChannelCard } from "./";
import { fetchFromAPI } from "../utils/fetchFromAPI";
import { fetchVideosChannel } from "./api/creator/videoCreatorSlice";
import { useDispatch, useSelector } from "react-redux";

const ChannelDetail = () => {
  const [channelDetail, setChannelDetail] = useState();
  const [videos, setVideos] = useState(null);
  const { userId } = useLocation().state || {};
  const { videosChannel } = useSelector((state) => state.videosCreator);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchVideosChannel({ shortlink: userId.shortUrl, page: 1, limit: 10 })
    );
  }, [userId.shortUrl]);

  // List of tags (can be dynamic or static)
  const tags = [
    "Tech",
    "Music",
    "Gaming",
    "News",
    "Education",
    "Movies",
    "Sports",
    "Science",
    "Health",
    "Travel",
    "Lifestyle",
    "Finance",
    "Fashion",
    "Art",
    "Food",
    "Politics",
    "Business",
    "Photography",
    "Coding",
    "Fitness",
    "Tech",
    "Music",
    "Gaming",
    "News",
    "Education",
    "Movies",
    "Sports",
    "Science",
    "Health",
    "Travel",
    "Lifestyle",
    "Finance",
    "Fashion",
    "Art",
    "Food",
    "Politics",
    "Business",
    "Photography",
    "Coding",
    "Fitness",
  ];

  return (
    <Box minHeight="95vh">
      <Box>
        <div
          style={{
            height: "300px",
            background:
              "linear-gradient(90deg, rgba(0,238,247,1) 0%, rgba(206,3,184,1) 100%, rgba(0,212,255,1) 100%)",
            zIndex: 10,
          }}
        />
        <ChannelCard
          channelDetail={channelDetail}
          userId={userId}
          marginTop="-93px"
        />
      </Box>
      {/* Tag list */}
      <Box p={2}>
        <Box
          sx={{
            overflowX: "auto", // Cho phép cuộn ngang
            whiteSpace: "nowrap", // Ngăn các thẻ tự động xuống dòng
            ml: { sm: "100px" },
            mr: { sm: "100px" },
          }}
        >
          <Stack direction="row" spacing={2}>
            {tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                clickable
                sx={{
                  backgroundColor: "#fbc50c",
                  "&:hover": {
                    backgroundColor: "#d4a000", // Màu phù hợp với #fbc50c khi hover
                  },
                }}
              />
            ))}
          </Stack>
        </Box>
      </Box>

      <Box p={2} display="flex">
        <Box sx={{ mr: { sm: "100px" } }} />
        <Videos videos={videosChannel?.documents} />
      </Box>
    </Box>
  );
};

export default ChannelDetail;
