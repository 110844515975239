import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const VideoPlayer = () => {
  const videoRef = useRef(null);
  const { videoUpload, newVideoData, statusNewVideo, errorNewVideo } = useSelector((state) => state.videos);
  const { id } = useParams();
  useEffect(() => {
    const fetchM3u8Url = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_BASE_URL}/api/serveVideo/get-video-url/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'text',
        });

        const m3u8Content = response.data;
        console.log("m3u8Content:", m3u8Content);

        const blob = new Blob([m3u8Content], { type: 'application/vnd.apple.mpegurl' });
        const m3u8Url = URL.createObjectURL(blob);

        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.config.xhrSetup = (xhr) => {
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
          };
          hls.loadSource(m3u8Url);
          hls.attachMedia(videoRef.current);
        } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
          videoRef.current.src = m3u8Url;
        }
      } catch (error) {
        console.error('Error fetching m3u8 URL:', error);
      }
    };

    fetchM3u8Url();
  }, []);

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        width: '100vw', // Full viewport width
        height: '100vh', // Full viewport height
        overflow: 'hidden', // Hide overflow to prevent scrolling
        padding: 2 
      }}
    >
      <Box 
        sx={{ 
          width: '100%', 
          maxWidth: '100%', 
          position: 'relative', 
          paddingBottom: '56.25%', // 16:9 aspect ratio
          height: 0 
        }}
      >
        <video
          ref={videoRef}
          controls
          style={{ 
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'contain', // Adjust as needed
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
          }}
        />
      </Box>
    </Box>
  );
};

export default VideoPlayer;
