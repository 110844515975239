import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { Typography, Box, Stack, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useAccount, useConnect, useSendTransaction, useWaitForTransactionReceipt } from 'wagmi';
import { Videos, Loader } from ".";
import { fetchFromAPI } from "../utils/fetchFromAPI";
import { parseEther } from "viem";

const VideoDetail = () => {
  const [videoDetail, setVideoDetail] = useState(null);
  const [videos, setVideos] = useState(null);
  const { id } = useParams();
  const { address } = useAccount();
  const { connect, connectors } = useConnect();

  const { sendTransaction, data: hash, isPending, error } = useSendTransaction();

  useEffect(() => {
    fetchFromAPI(`videos?part=snippet,statistics&id=${id}`)
      .then((data) => setVideoDetail(data.items[0]));

    fetchFromAPI(`search?part=snippet&relatedToVideoId=${id}&type=video`)
      .then((data) => setVideos(data.items));
  }, [id]);

  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({ hash });

  const handlePayToWatch = () => {
    if (address) {
      sendTransaction({
        to: '0x67592c9B99Ccc92dAF60C7F2D23303Ccd2AEe26B',
        value: parseEther("0.0001"),
        onSuccess(data) {
          console.log('Transaction successful', data);
        },
        onError(error) {
          console.error('Transaction failed', error);
        }
      });
    } else {
      connect(connectors[0]);
    }
  };

  if (!videoDetail?.snippet) return <Loader />;

  const { snippet: { title, channelId, channelTitle }, statistics: { viewCount, likeCount } } = videoDetail;

  return (
    <Box minHeight="95vh">
      <Stack direction={{ xs: "column", md: "row" }}>
        <Box flex={1}>
          <Box sx={{ width: "100%", position: "sticky" }}>
            <Box sx={{ position: 'relative', paddingBottom: '100%', height: 0 }}>
              <ReactPlayer
                url={`https://www.youtube.com/watch?v=${id}`}
                className="react-player"
                controls={false}
                playing={false}
                width="100%"
                height="100%"
                style={{ position: 'absolute', top: 0, left: 0 }}
              />
              {!isConfirmed && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '50%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1,
                  }}
                >
                  <Button variant="contained" color="primary" onClick={handlePayToWatch}>
                    Pay to Watch 0.0001BNB
                  </Button>
                </Box>
              )}
            </Box>

            <Typography sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
            }} color="#fff" variant="h5" fontWeight="bold" p={2}>
              {title}
            </Typography>
            {hash && <Typography color="#fff" variant="h6" p={2}>Transaction Hash: {hash}</Typography>}
            {isConfirming && <Typography color="#fff" variant="h6" p={2}>Waiting for confirmation...</Typography>}
            {isConfirmed && <Typography color="#fff" variant="h6" p={2}>Transaction confirmed. Enjoy the video!</Typography>}
            {error && <Typography color="#fff" variant="h6" p={2}>Error: {error.shortMessage || error.message}</Typography>}

            <Stack direction="row" justifyContent="space-between" sx={{ color: "#fff" }} py={1} px={2}>
              <Link to={`/channel/${channelId}`}>
                <Typography variant={{ sm: "subtitle1", md: 'h6' }} color="#fff">
                  {channelTitle}
                  <CheckCircleIcon sx={{ fontSize: "12px", color: "gray", ml: "5px" }} />
                </Typography>
              </Link>
              <Stack direction="row" gap="20px" alignItems="center">
                <Typography variant="body1" sx={{ opacity: 0.7 }}>
                  {parseInt(viewCount).toLocaleString()} views
                </Typography>
                <Typography variant="body1" sx={{ opacity: 0.7 }}>
                  {parseInt(likeCount).toLocaleString()} likes
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>
        <Box px={2} py={{ md: 1, xs: 5 }} justifyContent="center" alignItems="center">
          <Videos videos={videos} direction="column" />
        </Box>
      </Stack>
    </Box>
  );
};

export default VideoDetail;
