import React, { useEffect, useState } from 'react';
import { Box, CardContent, Button, TextField, styled, Typography, FormControl, InputLabel, Select, MenuItem, debounce, OutlinedInput, InputAdornment, FormHelperText, Grid } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { uploadNewVideo, uploadVideo } from '../api/video/videoSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import ModalCustom from '../ModalCustom/ModalCustom';
import { io } from 'socket.io-client';
import ModalUpload from '../ModalCustom/ModalUpload';


const UploadVideoCard = ({ marginTop }) => {
    const [videoFile, setVideoFile] = useState(null);
    const [videoPreview, setVideoPreview] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [priceUSD, setPriceUSD] = useState('');
    const [priceBNB, setPriceBNB] = useState('');
    const [tags, setTags] = useState('');
    const [categoryIds, setCategoryIds] = useState([]);
    const [duration, setDuration] = useState('');
    const [totalSize, setTotalSize] = useState('');
    const [typeOfVideo, setTypeOfVideo] = useState('');
    const [streamUrl, setStreamUrl] = useState('');
    const [enumMode, setEnumMode] = useState('');
    const [thumbnails, setThumbnails] = useState(null);
    const [thumbnailPreview, setThumbnailPreview] = useState('');
    const [dragOver, setDragOver] = useState(false);
    const [uploadProgress, setUploadProgress] = useState("Uploading...");
    const [uploadCompleted, setUploadCompleted] = useState(false);
    const [modalUpload, setModalUpload] = useState(false);
    const { user,token } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { videoUpload, newVideoData, statusNewVideo, errorNewVideo } = useSelector((state) => state.videos);
    const { categories, loadingCategory, errorCategory } = useSelector((state) => state.category);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    // const userId = localStorage.getItem('userId');
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    useEffect(() => {
        if (videoUpload) {
            setTypeOfVideo(videoUpload.typeOfVideo);
            setDuration(videoUpload.duration);
            setStreamUrl(videoUpload.urlLink);
            setTotalSize(videoUpload.totalSize);
        }
    }, [videoUpload]);

    const fetchBNBPrice = debounce(async (usd) => {
        if (usd) {
            try {
                const response = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
                    params: {
                        ids: 'binancecoin',
                        vs_currencies: 'usd',
                    },
                });

                const rate = response.data.binancecoin.usd;
                setPriceBNB((usd / rate).toFixed(6)); // Convert USD to BNB, assuming 6 decimal places
            } catch (error) {
                console.error('Failed to fetch BNB price:', error);
            }
        } else {
            setPriceBNB('');
        }
    }, 500); // 500ms debounce delay
    const handleUSDChange = (e) => {
        const usdValue = e.target.value;
        setPriceUSD(usdValue);
        fetchBNBPrice(usdValue);
    };

    const handleVideoUpload = (file) => {
        if (file) {
            setVideoFile(file);
            setVideoPreview(URL.createObjectURL(file));
            setUploadCompleted(false);
            setModalUpload(true);
            if (user) {
                const newSocket = io(`${API_BASE_URL}/socket`, {
                    query: { userId: user }, // Truyền user trong query
                })
                newSocket.on('connect', () => {
                    console.log('Connected to /socket namespace with user:', user);
                });
                newSocket.on('uploadProgress', (data, ackCallback) => {
                    console.log("Data upload", data);
                    console.log(`Upload progress: ${data.uploadedFilesCount} of ${data.totalFiles}`);
                    setUploadProgress(data);
                    if (data.uploadedFilesCount === data.totalFiles) {
                        setUploadCompleted(true);
                    }
                    if (typeof ackCallback === 'function') {
                        ackCallback({ status: 'success' });
                        console.log('ACK received for uploadProgress');
                    } else {
                        console.error('No acknowledgment received from client');
                    }
                });
                newSocket.on('disconnect', () => {
                    console.log('Disconnected from /socket namespace');
                });
                return () => {
                    newSocket.disconnect();
                };
            } else {
                console.log('Please enter a userId before connecting');
            }
        }
    };


    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        handleVideoUpload(file);

    };

    const handleDrop = (event) => {
        event.preventDefault();
        setDragOver(false);
        const file = event.dataTransfer.files[0];
        handleVideoUpload(file);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setDragOver(false);
    };

    const handleThumbnailUpload = (event) => {
        const file = event.target.files[0];
        // setVideoFile(file);
        if (file) {
            setThumbnails(file);
            setThumbnailPreview(URL.createObjectURL(file));
            console.log("Url", URL.createObjectURL(file), file.name)
        }
    };

    const handleUpload = async () => {
        if (videoFile) {
            // Tạo đối tượng chứa dữ liệu video
            const videoData = {
                title,
                description,
                priceUSD,
                priceBNB,
                tags,
                categoryIds,
                typeOfVideo,
                streamUrl,
                enumMode,
                duration,
                totalSize,
                videoFile,
                thumbnails
            };

            try {
                await dispatch(uploadNewVideo({videoData,token}));
                console.log('Video uploaded successfully');
            } catch (error) {
                console.error('Failed to upload video:', error);
            }
        } else {
            alert('Please select a video file to upload.');
        }
    };
    useEffect(() => {
        if (videoFile) {
            dispatch(uploadVideo({ videoFile,token }));
        }
    }, [videoFile])

    useEffect(() => {
        console.log("categoryIds", categoryIds);
    }, [categoryIds])

    const inputStyles = {
        width: "90%",
        maxWidth: '500px',
        backgroundColor: "#1A1C1F",
        borderRadius: '10px',
        '& .MuiInputBase-input': {
            color: '#FFFFDB',
        },
        '& .MuiInputLabel-root': {
            color: 'white',
        },
    };
    const inputStyles2 = {
        width: "90%",
        backgroundColor: "#1A1C1F",
        '& .MuiInputBase-input': {
            color: '#FFFFDB',
        },
        '& .MuiInputLabel-root': {
            color: 'white',
        },
        borderRadius: '10px',
    };
    const inputStyles3 = {
        width: "90%",
        maxWidth: '500px',
        backgroundColor: "#1A1C1F",
        '& .MuiInputBase-input.Mui-disabled': {
            color: '#FFFFDB', // Maintain text color when disabled
            WebkitTextFillColor: '#FFFFDB', // For Safari support
        },
        '& .MuiInputLabel-root.Mui-disabled': {
            color: 'white', // Maintain label color when disabled
        },
        borderRadius: '10px',
    };

    const buttonStyles = {
        width: "90%",
        maxWidth: '500px',
        borderRadius: '10px',
    };

    const formControlStyles = {
        width: "90%",
        maxWidth: '500px',
        backgroundColor: "#1A1C1F",
        borderRadius: '10px',
        '& .MuiInputLabel-root': {
            color: 'white',
        },
    };
    return (
        <Box
            sx={{
                boxShadow: 'none',
                borderRadius: '20px',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'space-between',
                alignItems: 'center',
                width: { xs: "95%", sm: "90%", md: "80%" },
                height: 'auto',
                margin: 'auto',
                marginTop,
                backgroundColor: "#242526",
                padding: { xs: 2, sm: 3, md: 4 },
            }}
        >
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    color: '#fff',
                    alignItems: 'center',
                    width: { xs: "100%", md: "50%" },
                    mb: { xs: 2, md: 0 },
                }}
            >
                <Grid
                    container
                    spacing={3}
                    sx={{
                        width: "100%",
                        justifyContent: 'center',
                        alignItems: 'flex-start', // Align items to the top
                    }}
                >
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="outlined-title"
                            label="Video Title (use # for hashtags)"
                            variant="filled"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="e.g., #funny #tutorial"
                            sx={inputStyles}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>

                        <FormControl variant="filled" sx={inputStyles}>
                            <InputLabel id="category-label" sx={{ color: 'white' }}>Category</InputLabel>
                            <Select
                                labelId="category-label"
                                id="category"
                                multiple
                                value={categoryIds.map(id => categories.find(category => category._id === id)?.name)}
                                onChange={(e) => {
                                    const selectedNames = e.target.value;
                                    const selectedIds = selectedNames.map(name => categories.find(category => category.name === name)?._id);
                                    setCategoryIds(selectedIds);
                                }}
                                sx={{ color: '#FFFFDB' }}
                            >
                                {categories?.map((category) => (
                                    <MenuItem key={category._id} value={category.name}>
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </Select>

                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            id="outlined-typeOfVideo"
                            label="Type of Video"
                            variant="filled"
                            value={typeOfVideo}
                            onChange={(e) => setTypeOfVideo(e.target.value)}
                            placeholder="e.g., Movie, Tutorial"
                            sx={inputStyles}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            id="outlined-streamUrl"
                            label="Stream URL"
                            variant="filled"
                            value={streamUrl}
                            onChange={(e) => setStreamUrl(e.target.value)}
                            placeholder="e.g., https://example.com/stream"
                            sx={inputStyles}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>


                        <TextField
                            id="outlined-total"
                            label="Total Size (in GB)"
                            variant="filled"
                            value={((totalSize / 1048576)/100).toFixed(2)} // Chuyển đổi từ KB sang GB
                            onChange={(e) => setTotalSize(e.target.value)}
                            placeholder="e.g., 2.5"
                            disabled
                            sx={inputStyles3}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            id="outlined-duration"
                            label="Duration (in minutes)"
                            variant="filled"
                            value={(duration / 60).toFixed(2)}
                            onChange={(e) => setDuration(e.target.value)}
                            placeholder="e.g., 120"
                            disabled
                            sx={inputStyles3}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl sx={inputStyles2}>
                            <InputLabel htmlFor="outlined-adornment-amount" sx={{ color: "white" }}>Price (USD)</InputLabel>
                            <OutlinedInput
                                id="outlined-priceUSD"
                                label="Price (USD)"
                                endAdornment={<InputAdornment position="end" sx={{
                                    color: "white",
                                    '& .MuiTypography-root': {
                                        color: 'white',
                                    }
                                }}>USD</InputAdornment>}
                                value={priceUSD}
                                onChange={handleUSDChange}
                                placeholder="e.g., 19.99"
                                sx={{ color: '#FFFFDB' }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>

                        <FormControl sx={inputStyles2}>
                            <InputLabel htmlFor="outlined-adornment-amount" sx={{ color: "white" }}>Price (BNB)</InputLabel>
                            <OutlinedInput
                                id="outlined-priceBNB"
                                label="Price (BNB)"
                                endAdornment={<InputAdornment position="end" sx={{
                                    color: "white",
                                    '& .MuiTypography-root': {
                                        color: 'white',
                                    }
                                }}>BNB</InputAdornment>}
                                value={priceBNB}
                                placeholder="e.g., 0.05"
                                sx={{ color: '#FFFFDB' }}
                            />

                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>

                        <FormControl variant="filled" sx={inputStyles}>
                            <InputLabel id="enumMode-label" sx={{ color: 'white' }}>Mode</InputLabel>
                            <Select
                                labelId="enumMode-label"
                                id="enumMode"
                                value={enumMode}
                                onChange={(e) => setEnumMode(e.target.value)}
                                sx={{ color: '#FFFFDB' }}
                            >
                                <MenuItem value="public">Public</MenuItem>
                                <MenuItem value="private">Private</MenuItem>
                                <MenuItem value="unlisted">Unlisted</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            id="outlined-tags"
                            label="Tags"
                            variant="filled"
                            value={tags}
                            onChange={(e) => setTags(e.target.value)}
                            placeholder="e.g., #action, #comedy"
                            sx={inputStyles}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            id="outlined-description"
                            label="Description"
                            variant="filled"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            multiline
                            rows={4}
                            sx={inputStyles}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Button
                            component="label"
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            sx={{
                                width: "100%",
                                borderRadius: '10px',
                            }}
                        >
                            {thumbnails ? 'Change Thumbnail' : 'Upload Thumbnails'}
                            <VisuallyHiddenInput
                                type="file"
                                accept="image/*"
                                onChange={handleThumbnailUpload}
                            />
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        {thumbnailPreview && (
                            <Box
                                component="img"
                                src={thumbnailPreview}
                                alt="Thumbnail Preview"
                                sx={{
                                    width: { xs: '200px', md: '250px' },
                                    height: { xs: '200px', md: '250px' },
                                    borderRadius: '10px',
                                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                                }}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleUpload}
                            sx={{
                                width: "100%",
                                borderRadius: '10px',
                                backgroundColor: '#1A73E8',
                            }}
                        >
                            Upload
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>

            {videoPreview && (
                <Box
                    sx={{
                        width: { xs: '100%', md: '600px' },
                        height: { xs: '300px', md: '600px' },
                        mb: { xs: 2, md: 0 },
                    }}
                >
                    <video
                        controls
                        src={videoPreview}
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '10px',
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                        }}
                    />

                </Box>
            )}

            {!videoPreview && (
                <Box
                    sx={{
                        width: { xs: '100%', md: '600px' },
                        height: { xs: '300px', md: '300px' },
                        border: '2px dashed #1A73E8',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        backgroundColor: dragOver ? 'rgba(26, 115, 232, 0.2)' : 'transparent',
                    }}
                    onClick={() => document.getElementById('video-input').click()}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                >
                    <VisuallyHiddenInput
                        type="file"
                        accept="video/*"
                        id="video-input"
                        onChange={handleFileSelect}
                    />
                    <Typography variant="h6" sx={{ color: '#fff' }}>
                        {dragOver ? 'Drop video here' : 'Drag & Drop video or Click to Upload'}
                    </Typography>
                </Box>

            )}

            {statusNewVideo === "succeeded" ? (<ModalCustom />) : null}
            <ModalUpload modalUpload={modalUpload} setModalUpload={setModalUpload} uploadCompleted={uploadCompleted} uploadProgress={uploadProgress} />
        </Box>
    );
};

export default UploadVideoCard;
