import { IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close'; 
function AdBannerMobile({setAdMobileVisible}) {
  useEffect(() => {
    // Create and insert the script for the ad setup
    const script1 = document.createElement('script');
    script1.type = 'text/javascript';
    script1.id = 'ad-setup-script'; // Unique ID for the setup script
    script1.innerHTML = `
      atOptions = {
        'key' : 'a1f1a1a28b6e2c8ff85fd176d5242af4',
        'format' : 'iframe',
        'height' : 60,
        'width' : 468,
        'params' : {}
      };
    `;
    const adBanner = document.getElementById('ad-banner');
    adBanner.appendChild(script1);

    // Create and insert the script to load the ad
    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.id = 'ad-load-script'; // Unique ID for the load script
    script2.src = '//www.topcreativeformat.com/a1f1a1a28b6e2c8ff85fd176d5242af4/invoke.js';
    adBanner.appendChild(script2);

    return () => {
      const setupScript = document.getElementById('ad-setup-script');
      const loadScript = document.getElementById('ad-load-script');
      if (setupScript) adBanner.removeChild(setupScript);
      if (loadScript) adBanner.removeChild(loadScript);
    };
  }, []);

  return (
    <div
      id="ad-banner"
      style={{
        position: 'fixed',
        bottom: '0px',
        transform: 'translateX(0%)', // Center the banner horizontally
        width: '468px', // Adjust width if needed
        height: '60px', // Adjust height if needed
        zIndex: 1000, // Ensure the banner is on top
        backgroundColor: '#f0f0f0', // Optional background color for visibility
      }}
    >
       <IconButton
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1100,
                color: '#000',
                backgroundColor: 'transparent',
                width: '24px',  // Smaller width
                height: '24px', // Smaller height
                padding: '0',   // Remove extra padding
                '& .MuiSvgIcon-root': {
                  fontSize: '18px', // Smaller icon size
                },
              }}
      onClick={()=> setAdMobileVisible(false)}
            >
              <CloseIcon />
            </IconButton>
    </div>
  );
}

export default AdBannerMobile;
