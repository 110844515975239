// src/redux/slices/userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const fetchUserProfile = createAsyncThunk(
  "user/fetchUserProfile",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/users/me`, {
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("User data", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  }
);
export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (
    { userInfo, token },
    { rejectWithValue }
  ) => {
    try {
      // const token = localStorage.getItem('token');
      const formData = new FormData();
      console.log("Token", token);
      // Append form data fields
      if (userInfo.avatar) formData.append("avatar", userInfo.avatar);
      if (userInfo.email) formData.append("email", userInfo.email);
      if (userInfo.fullName) formData.append("fullName", userInfo.fullName);
      if (userInfo.phoneNumber) formData.append("phoneNumber", userInfo.phoneNumber);
      if (userInfo.priceBNB) formData.append("priceBNB", userInfo.priceBNB);

      const response = await axios.put(
        `${API_BASE_URL}/api/users/update-profile`,
        formData,
        {
          headers: {
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    infoUser: {},
    status: "idle",
    error: null,
    statusUser: false,
    userData: {},
    errorUser: null,
  },
  reducers: {
    setInfoUser: (state, action) => {
      state.infoUser = { ...state.infoUser, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.infoUser = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchUserProfile.pending, (state) => {
        state.loading = true;
        state.errorUser = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.statusUser = false;
        state.userData = action.payload;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.statusUser = false;
        state.errorUser = action.payload;
      });
  },
});

export const { setInfoUser } = userSlice.actions;
export default userSlice.reducer;
