import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
  Divider,
  CircularProgress,
} from "@mui/material";
import { makeStyles, styled, useTheme } from "@mui/material/styles";
import DiamondIcon from "@mui/icons-material/Diamond";
import CheckIcon from "@mui/icons-material/Check";
import {
  clearPayVideoState,
  makePayVideo,
} from "../api/transaction/transactionSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


// Define CustomDialog with enhanced styling
const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    padding: theme.spacing(4),
    maxWidth: "500px",
    margin: "auto",
    background: "linear-gradient(145deg, #ffffff 0%, #f7f7f7 100%)",
    boxShadow: theme.shadows[8],
    overflow: 'hidden',
    border: `1px solid ${theme.palette.divider}`,
  },
}));
// Styled Typography for title and content
const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(2),
  textAlign: "center",
}));

const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(2),
}));

// Styled Buttons with hover effect

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#4caf50",
  color: "#fff",
  borderRadius: "25px",
  padding: theme.spacing(1.5, 4),
  fontWeight: "bold",
  textTransform: "uppercase",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
  "&:hover": {
    backgroundColor: "#388e3c",
    boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.3)",
  },
}));


const dialogCommonStyles = {
  "& .MuiPaper-root": {
    borderRadius: "16px",
    padding: "24px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
    transition: "transform 0.3s ease",
    transform: "scale(1)",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
};

// Define specific styles for each dialog
const errorDialogStyles = {
  ...dialogCommonStyles,
  "& .MuiPaper-root": {
    ...dialogCommonStyles["& .MuiPaper-root"],
    borderRadius: "20px",
    transform: "scale(1)",
    "&:hover": {
      transform: "scale(1.03)",
    },
  },
};

const confirmationDialogStyles = {
  ...dialogCommonStyles,
};

const UpgradeVIPDialog = ({
  openModal,
  setOpenModal,
  errorMessage,
  priceUSD,
  videoId,
}) => {
  const { token } = useSelector((state) => state.auth);
  const theme = useTheme();
  const dispatch = useDispatch();

  const { payVideo, loadingPayVideo, errorPayVideo } = useSelector(
    (state) => state.transactions
  );
  const [loginDialogOpen, setLoginDialogOpen] = useState(false); // State for login dialog
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const handleBack = () => {
    window.history.back();
  };

  const handleConfirmPayment = () => {
    if (!token) {
      // If user is not logged in, show login dialog
      setLoginDialogOpen(true);
    } else {
      setConfirmationDialogOpen(true);
    }
  };
  const handleConfirmYes = () => {
    setConfirmationDialogOpen(false);
    dispatch(makePayVideo({ videoId, token }));
  };

  const handleReloadPage = () => {
    window.location.reload(); // Reload the page to update
  };

  const handleGoogleLogin = () => {
    window.location.href = `${API_BASE_URL}/api/auth/google`; // Redirect to Google login
  };
  useEffect(() => {
    if (errorPayVideo?.error) {
      setIsErrorDialogOpen(true); // Mở popup lỗi
    }
  }, [errorPayVideo]);

  const handleClear = () => {
    dispatch(clearPayVideoState());
  };

  return (
    <>
      <Dialog
        open={openModal}
        onClose={() => {}}
        aria-labelledby="upgrade-vip-dialog-title"
        aria-describedby="upgrade-vip-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "12px",
            overflow: "hidden",
            boxShadow: `0 6px 12px rgba(0, 0, 0, 0.15)`,
            padding: "24px",
            width: "400px",
            backgroundImage:
              "linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)",
            color: "#fff", // Text color for contrast against gradient
          },
        }}
      >
        {/* Nội dung hộp thoại chính */}
        <DialogTitle
          id="upgrade-vip-dialog-title"
          sx={{
            backgroundColor: "#c32294",
            color: theme.palette.primary.contrastText,
            padding: "20px",
            textAlign: "center",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          Only {priceUSD}$ <DiamondIcon />
        </DialogTitle>
        <DialogContent
          sx={{
            padding: "20px",
            fontSize: "16px",
            lineHeight: "1.5",
            color: theme.palette.text.primary,
          }}
        >
          <Typography
            id="upgrade-vip-dialog-description"
            variant="body1"
            color="white"
            sx={{ marginBottom: "20px" }}
          >
            To enjoy an enhanced viewing experience, consider upgrading to VIP.
            You'll get exclusive benefits:
          </Typography>
          <Box sx={{ marginBottom: "20px" }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginBottom: "10px" }}
            >
              VIP Benefits
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <CheckIcon sx={{ marginRight: "10px", color: "#22c1c3" }} />
              <Typography color="white" variant="body1">
                High Quality
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <CheckIcon sx={{ marginRight: "10px", color: "#22c1c3" }} />
              <Typography color="white" variant="body1">
                No Ads
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <CheckIcon sx={{ marginRight: "10px", color: "#22c1c3" }} />
              <Typography color="white" variant="body1">
                Watch VIP Videos
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ marginBottom: "20px" }} />
          <Typography variant="body2">
            By upgrading, you can enjoy an uninterrupted and premium video
            experience. Don’t miss out!
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "16px",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={handleBack}
            variant="outlined"
            sx={{
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              textTransform: "uppercase",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: theme.palette.primary.light,
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              },
            }}
          >
            Back to home
          </Button>
          <Button
            onClick={handleConfirmPayment}
            variant="contained"
            sx={{
              backgroundColor: "#c32294",
              color: theme.palette.secondary.contrastText,
              textTransform: "uppercase",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: theme.palette.secondary.dark,
              },
            }}
          >
            {loadingPayVideo === "pending" ? (
              <CircularProgress size={24} sx={{ color: "black" }} />
            ) : (
              <DiamondIcon />
            )}
            Upgrade VIP
          </Button>
        </DialogActions>
      </Dialog>

      {/* Thank You Dialog - hiển thị khi thanh toán thành công */}
      {payVideo && (
           <CustomDialog open={payVideo} onClose={handleReloadPage}>
           <DialogTitle
             sx={{
               backgroundColor: "#4caf50",
               color: "#fff",
               borderRadius: "15px 15px 0 0",
               textAlign: "center",
               padding: "16px 24px",
               fontSize: "20px",
               fontWeight: "bold",
             }}
           >
             <Typography variant="h6">Payment successful   <CheckCircleIcon sx={{ mr: 1 }} /></Typography>
             <Typography variant="h6">Thank You!</Typography>
      
           </DialogTitle>
           <DialogContent sx={{ textAlign: "center", padding: "20px" }}>
            
           </DialogContent>
           <DialogActions sx={{ justifyContent: "center", padding: "16px" }}>
             <CustomButton onClick={handleReloadPage}>Enjoy your VIP benefits!</CustomButton>
           </DialogActions>
         </CustomDialog>
      )}

      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        sx={confirmationDialogStyles}
      >
        <DialogTitle
          sx={{
            fontSize: "24px",
            fontWeight: "600",
            color: "#333",
            textAlign: "center",
            paddingBottom: "16px",
            borderBottom: "2px solid #f0f0f0",
          }}
        >
          Confirm Payment
        </DialogTitle>
        <DialogContent
          sx={{
            fontSize: "16px",
            color: "#555",
            textAlign: "center",
            padding: "20px",
            lineHeight: "1.5",
          }}
        >
          <Typography>
            Are you sure you want to pay <strong>{priceUSD}$</strong> for VIP
            upgrade?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            padding: "20px",
            backgroundColor: "#fafafa",
          }}
        >
          <Button
            onClick={() => setConfirmationDialogOpen(false)}
            sx={{
              backgroundColor: "#e0e0e0",
              "&:hover": {
                backgroundColor: "#c5c5c5",
              },
              color: "#333",
              padding: "12px 24px",
              borderRadius: "12px",
              fontWeight: "500",
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmYes}
            sx={{
              backgroundColor: "#00796b",
              "&:hover": {
                backgroundColor: "#004d40",
              },
              color: "#ffffff",
              padding: "12px 24px",
              borderRadius: "12px",
              fontWeight: "500",
              textTransform: "none",
            }}
          >
            Yes, Pay
          </Button>
        </DialogActions>
      </Dialog>
      {errorPayVideo && (
        <Dialog
          open={isErrorDialogOpen}
          onClose={() => setIsErrorDialogOpen(false)}
          sx={errorDialogStyles}
        >
          <DialogTitle
            sx={{
              fontSize: "26px",
              fontWeight: "bold",
              color: "#333",
              textAlign: "center",
              paddingBottom: "16px",
              borderBottom: "2px solid #f0f0f0",
            }}
          >
            Insufficient Funds
          </DialogTitle>
          <DialogContent
            sx={{
              fontSize: "18px",
              color: "#d32f2f",
              textAlign: "center",
              padding: "20px",
              lineHeight: "1.5",
            }}
          >
            <Typography>
              {errorPayVideo.error || "Something went wrong. Please try again."}
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              padding: "20px",
            }}
          >
            <Button
              onClick={() => {
                setIsErrorDialogOpen(false);
                handleClear();
              }}
              sx={{
                backgroundColor: "#e0e0e0",
                "&:hover": {
                  backgroundColor: "#bdbdbd",
                },
                color: "#333",
                padding: "12px 24px",
                borderRadius: "12px",
                fontWeight: "500",
                textTransform: "none",
              }}
            >
              Close
            </Button>
            <Button
              onClick={() => {
                setIsErrorDialogOpen(false);
                window.location.href = "/userprofile";
              }}
              sx={{
                backgroundColor: "#00796b",
                "&:hover": {
                  backgroundColor: "#004d40",
                },
                color: "#ffffff",
                padding: "12px 24px",
                borderRadius: "12px",
                fontWeight: "500",
                textTransform: "none",
              }}
            >
              Deposit
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Login Dialog */}
      <CustomDialog
        open={loginDialogOpen}
        onClose={() => setLoginDialogOpen(false)}
      >
        <DialogTitleStyled>
          <Typography variant="h6">Login Required</Typography>
        </DialogTitleStyled>
        <DialogContentStyled>
          <Typography variant="body1">
            You need to be logged in to make a payment. Please log in to
            continue.
          </Typography>
        </DialogContentStyled>
        <DialogActions sx={{ justifyContent: "center", gap: 2 }}>
          <Button
            onClick={() => setLoginDialogOpen(false)}
            color="secondary"
            variant="outlined"
            sx={{
              borderRadius: "12px",
              padding: "8px 16px",
              textTransform: "uppercase",
              fontWeight: 600,
              borderColor: "#d32f2f",
              color: "#d32f2f",
              "&:hover": {
                backgroundColor: "#d32f2f",
                color: "#fff",
              },
            }}
          >
            Cancel
          </Button>
          <CustomButton
            onClick={handleGoogleLogin}
            color="primary"
            variant="contained"
          >
            Login with Google
          </CustomButton>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default UpgradeVIPDialog;
