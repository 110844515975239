import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const login = createAsyncThunk(
  'auth/login',
  async ({ addressKey, signature }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/auth/login`, {
        addressKey,
        signature,
      }, {
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
        }
      });
      
      return response.data; // Trả về dữ liệu để lưu vào state
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const loginWithGoogle = createAsyncThunk(
  'auth/loginWithGoogle',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/auth/google`, {
        headers: {
          'Accept': '*/*',
        },
      });

      return response.data; // Trả về dữ liệu nếu request thành công
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getNonce = createAsyncThunk(
  'auth/getNonce',
  async (address, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/users/get-nonce/${address}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    userData: null,
    error: null,
    token: null, // Store token in state
    loadingNonce: false,
    nonceData: null,
    errorNonce: null,
    user: null,
    statusLogin: 'idle',
    errorLogin: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.userId;
      state.token = action.payload.token;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      // Không lưu trữ token trong localStorage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload;
        state.token = action.payload.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getNonce.pending, (state) => {
        state.loadingNonce = true;
        state.errorNonce = null;
      })
      .addCase(getNonce.fulfilled, (state, action) => {
        state.loadingNonce = false;
        state.nonceData = action.payload;
      })
      .addCase(getNonce.rejected, (state, action) => {
        state.loadingNonce = false;
        state.errorNonce = action.payload;
      })
      .addCase(loginWithGoogle.pending, (state) => {
        state.statusLogin = 'loading';
      })
      .addCase(loginWithGoogle.fulfilled, (state, action) => {
        state.statusLogin = 'succeeded';
        state.user = action.payload.userId; // Giả sử payload chứa userId
        state.token = action.payload.token; // Lưu token từ payload
      })
      .addCase(loginWithGoogle.rejected, (state, action) => {
        state.statusLogin = 'failed';
        state.errorLogin = action.payload;
      });
  },
});

export const { setUser, logout } = authSlice.actions;
export default authSlice.reducer;
