import {
  Button,
  CircularProgress,
  Box,
  Typography,
  TextField,
  Modal,
  IconButton,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { makeTransactionDeposit } from "../api/transaction/transactionSlice";
import axios from "axios";
import { useState, useEffect } from "react";

const DepositModal = ({
  openDepositModal,
  setOpenDepositModal,
  usdtAmount,
  setUsdtAmount,
  handleSubmitDeposit,
  isConfirming,
  isConfirmed,
  transactionHash,
  addressFrom,
}) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [bnbAmount, setBnbAmount] = useState("0");
  const [exchangeRate, setExchangeRate] = useState(0);

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin,tether&vs_currencies=usd"
        );
        const rate = response.data.tether.usd / response.data.binancecoin.usd;
        setExchangeRate(rate);
        setBnbAmount((usdtAmount * rate).toFixed(7));
      } catch (error) {
        console.error("Failed to fetch exchange rate", error);
      }
    };

    fetchExchangeRate();
  }, [usdtAmount]);

  useEffect(() => {
    if (isConfirmed) {
      dispatch(
        makeTransactionDeposit({
          transactionHash,
          amountBNB: bnbAmount,
          amountUSDT: usdtAmount,
          addressFrom,
          token,
        })
      );
      // Reload the page after the deposit is confirmed
      // window.location.reload();
    }
  }, [
    isConfirmed,
    bnbAmount,
    usdtAmount,
    transactionHash,
    addressFrom,
    token,
    dispatch,
  ]);

  return (
    <Modal
      open={openDepositModal}
      onClose={() => {}}
      aria-labelledby="deposit-modal-title"
      aria-describedby="deposit-modal-description"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <Box
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <IconButton
          onClick={() => setOpenDepositModal(false)}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Enter USDT Amount
        </Typography>
        <TextField
          fullWidth
          label="USDT Amount"
          variant="outlined"
          value={usdtAmount}
          onChange={(e) => setUsdtAmount(e.target.value)}
        />
        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Amount BNB
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={bnbAmount}
          InputProps={{ readOnly: true }}
        />
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            onClick={handleSubmitDeposit}
            sx={{
              borderRadius: "10px",
              color: "black",
              backgroundColor: "#fbc50c",
              "&:hover": {
                backgroundColor: "#d4a000",
              },
            }}
            disabled={isConfirming || isConfirmed} // Disable button if transaction is in progress or confirmed
          >
            {isConfirming ? (
              <CircularProgress size={24} sx={{ color: "black" }} />
            ) : isConfirmed ? (
              <>
                <CheckCircleIcon sx={{ mr: 1 }} />
                Transaction Successful!
              </>
            ) : (
              "Confirm Deposit"
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DepositModal;
