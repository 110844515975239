import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// Async thunk to fetch videos based on shortlinkUser
export const fetchUserVideos = createAsyncThunk(
  "videos/fetchUserVideos",
  async ({ shortlinkUser, page = 1, limit = 10 }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/videos/shortlinkUser/${shortlinkUser}?page=${page}&limit=${limit}`,
        {
          headers: {
            Accept: "*/*",
          },
        }
      );

      console.log("Video", response.data);

      return response.data; // Return the data from the API response
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchVideosByTags = createAsyncThunk(
  "videos/fetchVideosByTags",
  async ({ keyword, page, limit }) => {
    const response = await axios.get(`${API_BASE_URL}/api/videos/search?keyword=${keyword}&page=${page}&limit=${limit}`);
    return response.data;
  }
);
export const fetchVideosByCategory = createAsyncThunk(
  "videos/fetchVideosByCategory",
  async ({ categoryId, page, limit }) => {
    console.log("Categoryid:", categoryId);
    const response = await axios.get(
      `${API_BASE_URL}/api/videos/category/${categoryId}?page=1&limit=10`
    );
    return response.data;
  }
);


export const uploadNewVideo = createAsyncThunk(
  "videos/uploadNewVideo",
  async ({videoData,token}, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("enumMode", videoData.enumMode);
      formData.append("typeOfVideo", videoData.typeOfVideo);
      formData.append("priceBNB", videoData.priceBNB);
      formData.append("streamUrl", videoData.streamUrl);
      formData.append("duration", videoData.duration);

      // Nếu thumbnails là một file, đảm bảo nó được thêm đúng cách
      if (videoData.thumbnails instanceof File) {
        formData.append("imageUrl", videoData.thumbnails);
      }

      formData.append("title", videoData.title);
      formData.append("tags", videoData.tags);
      formData.append("categoryIds", videoData.categoryIds);
      formData.append("description", videoData.description);
      formData.append("priceUSD", videoData.priceUSD);

      const response = await axios.post(
        `${API_BASE_URL}/api/videos`,
        formData,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data; // Return the data from the API response
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const uploadVideo = createAsyncThunk(
  "videos/uploadVideo",
  async ({ videoFile,token }, { rejectWithValue }) => {
    try {

      const formData = new FormData();
      formData.append("video", videoFile);
console.log("Token2", token);
      const response = await axios.post(
        `${API_BASE_URL}/api/uploadVideo/upload-video-bunny`,
        formData,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data; // Return the data from the API response
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchTopViewedVideos = createAsyncThunk(
  "videos/fetchTopViewedVideos",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/videos/top-views?page=${page}&limit=${limit}`,
        {}
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchNewVideos = createAsyncThunk(
  "videos/fetchNewVideos",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/videos/newest?page=${page}&limit=${limit}`,
        {}
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchFreeVideos = createAsyncThunk(
  "videos/fetchFreeVideos",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/videos/public?page=${page}&limit=${limit}`,
        {}
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchVipVideos = createAsyncThunk(
  "videos/fetchVipVideos",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/videos/private?page=${page}&limit=${limit}`,
        {}
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRelatedVideos = createAsyncThunk(
  'videos/fetchRelatedVideos',
  async ({ tags, page, limit }) => {
    const response = await axios.post(`${API_BASE_URL}/api/videos/get-related-video`, {
      tags,
      page,
      limit,
    });
    return response.data;
  }
);

export const likeVideo = createAsyncThunk(
  'videos/likeVideo',
  async ({id, token}, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/users/likeVideo`,
        { videoId: id },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchLikedVideos = createAsyncThunk(
  'videos/fetchLikedVideos',
  async ({ page, limit, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/videos/liked`, {
        params: { page, limit },
        headers: {
          'Accept': '*/*',
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchVideosStream = createAsyncThunk(
  'videos/fetchVideosStream',
  async ({ block_id, fromnum }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/serveVideo/get-list-video-cam`,{
        params: { block_id, fromnum },
        headers: {
          'Accept': '*/*',
        }
      });
      return response.data;
    } catch (error) {
      // Handle error response
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchLinkLiveStream = createAsyncThunk(
  'videos/fetchLinkLiveStream',
  async ({id}) => {
    const response = await axios.get(`${API_BASE_URL}/api/linkLiveStreams/${id}`);
    return response.data;
  }
);
// Video slice to handle state
const videoSlice = createSlice({
  name: "videos",
  initialState: {
    videosData: [],
    statusVideo: "idle",
    errorVideo: null,
    videoUpload: null,
    uploadStatus: "idle",
    uploadError: null,
    newVideoData: [],
    statusNewVideo: "idle",
    errorNewVideo: null,
    videoTag: [],
    loadingTag: "idle",
    errorTag: null,
    videoCategory: [],
    loadingCategory: "idle",
    errorCategory: null,
    topViewedVideos: [],
    loadingTopViewedVideos: "idle",
    errorTopViewedVideos: null,
    dataRelateVideo: [],
    loadingRelateVideo: "idle",
    errorRelateVideo: null,
    likes: {},
    loadingLike: false,
    errorLike: null,
    likedVideos: [],
    loadingLikeVideos: 'idle',
    errorLikeVideos: null,
    streamingVideos: [],
    loadingstreamingVideos: 'idle',
    errorstreamingVideos: null,
   linkStreamingVideos: [],
    loadingLinkStreamingVideos: 'idle',
    errorLinkStreamingVideos: null
  },
  reducers: {
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserVideos.pending, (state) => {
        state.statusVideo = "loading";
      })
      .addCase(fetchUserVideos.fulfilled, (state, action) => {
        state.statusVideo = "succeeded";
        state.videosData = action.payload;
      })
      .addCase(fetchUserVideos.rejected, (state, action) => {
        state.statusVideo = "failed";
        state.errorVideo = action.payload;
      })
      .addCase(uploadVideo.pending, (state) => {
        state.uploadStatus = "loading";
        state.uploadError = null;
      })
      .addCase(uploadVideo.fulfilled, (state, action) => {
        state.uploadStatus = "succeeded";
        state.videoUpload = action.payload;
      })
      .addCase(uploadVideo.rejected, (state, action) => {
        state.uploadStatus = "failed";
        state.uploadError = action.payload;
      })
      .addCase(uploadNewVideo.pending, (state) => {
        state.statusNewVideo = "loading";
        state.errorNewVideo = null;
      })
      .addCase(uploadNewVideo.fulfilled, (state, action) => {
        state.statusNewVideo = "succeeded";
        state.newVideoData = action.payload;
      })
      .addCase(uploadNewVideo.rejected, (state, action) => {
        state.statusNewVideo = "failed";
        state.errorNewVideo = action.payload;
      })
      .addCase(fetchVideosByTags.pending, (state) => {
        state.loadingTag = "pending";
        state.errorTag = null;
      })
      .addCase(fetchVideosByTags.fulfilled, (state, action) => {
        state.videoTag = action.payload;
        state.loadingTag = "succeeded";
      })
      .addCase(fetchVideosByTags.rejected, (state, action) => {
        state.loadingTag = "failed";
        state.errorTag = action.error.message;
      })
      .addCase(fetchVideosByCategory.pending, (state) => {
        state.loadingCategory = "pending";
        state.errorCategory = null;
      })
      .addCase(fetchVideosByCategory.fulfilled, (state, action) => {
        state.videoCategory = action.payload;
        state.loadingCategory = "succeeded";
      })
      .addCase(fetchVideosByCategory.rejected, (state, action) => {
        state.loadingCategory = "failed";
        state.errorCategory = action.error.message;
      })
      .addCase(fetchTopViewedVideos.pending, (state) => {
        state.loadingCategory = "pending";
        state.errorCategory = null;
      })
      .addCase(fetchTopViewedVideos.fulfilled, (state, action) => {
        state.videoCategory = action.payload;
        state.loadingCategory = "succeeded";
      })
      .addCase(fetchTopViewedVideos.rejected, (state, action) => {
        state.loadingCategory = "failed";
        state.errorCategory = action.error.message;
      })
      .addCase(fetchNewVideos.pending, (state) => {
        state.loadingCategory = "pending";
        state.errorCategory = null;
      })
      .addCase(fetchNewVideos.fulfilled, (state, action) => {
        state.videoCategory = action.payload;
        state.loadingCategory = "succeeded";
      })
      .addCase(fetchNewVideos.rejected, (state, action) => {
        state.loadingCategory = "failed";
        state.errorCategory = action.error.message;
      })
      .addCase(fetchFreeVideos.pending, (state) => {
        state.loadingCategory = "pending";
        state.errorCategory = null;
      })
      .addCase(fetchFreeVideos.fulfilled, (state, action) => {
        state.videoCategory = action.payload;
        state.loadingCategory = "succeeded";
      })
      .addCase(fetchFreeVideos.rejected, (state, action) => {
        state.loadingCategory = "failed";
        state.errorCategory = action.error.message;
      })
      .addCase(fetchVipVideos.pending, (state) => {
        state.loadingCategory = "pending";
        state.errorCategory = null;
      })
      .addCase(fetchVipVideos.fulfilled, (state, action) => {
        state.videoCategory = action.payload;
        state.loadingCategory = "succeeded";
      })
      .addCase(fetchVipVideos.rejected, (state, action) => {
        state.loadingCategory = "failed";
        state.errorCategory = action.error.message;
      }).addCase(fetchRelatedVideos.pending, (state) => {
        state.loadingRelateVideo = 'pending';
      })
      .addCase(fetchRelatedVideos.fulfilled, (state, action) => {
        state.loadingRelateVideo = 'succeeded';
        state.dataRelateVideo = action.payload;
      })
      .addCase(fetchRelatedVideos.rejected, (state, action) => {
        state.loadingRelateVideo = 'failed';
        state.errorRelateVideo = action.error.message;
      }).addCase(likeVideo.pending, (state) => {
        state.loadingLike = 'pending';
      })
      .addCase(likeVideo.fulfilled, (state, action) => {
        state.loadingLike = 'succeeded';
        // Optionally handle the state update based on action.payload
      })
      .addCase(likeVideo.rejected, (state, action) => {
        state.loadingLike = 'failed';
        state.errorLike = action.payload;
      }) .addCase(fetchLikedVideos.pending, (state) => {
        state.loadingLikeVideos = 'loading';
      })
      .addCase(fetchLikedVideos.fulfilled, (state, action) => {
        state.loadingLikeVideos = 'succeeded';
        state.likedVideos = action.payload;
      })
      .addCase(fetchLikedVideos.rejected, (state, action) => {
        state.loadingLikeVideos = 'failed';
        state.errorLikeVideos = action.payload;
      }).addCase(fetchVideosStream.pending, (state) => {
        state.loadingstreamingVideos = 'pending';
      })
      .addCase(fetchVideosStream.fulfilled, (state, action) => {
        state.loadingstreamingVideos = 'succeeded';
        state.streamingVideos = action.payload;
      })
      .addCase(fetchVideosStream.rejected, (state, action) => {
        state.loadingstreamingVideos = 'failed';
        state.errorstreamingVideos = action.payload;
      }).addCase(fetchLinkLiveStream.pending, (state) => {
        state.loadingLinkStreamingVideos = 'pending';
      })
      .addCase(fetchLinkLiveStream.fulfilled, (state, action) => {
        state.loadingLinkStreamingVideos = 'succeeded';
        state.linkStreamingVideos = action.payload;
      })
      .addCase(fetchLinkLiveStream.rejected, (state, action) => {
        state.loadingLinkStreamingVideos = 'failed';
        state.errorLinkStreamingVideos = action.payload;
      });
  },
});
export const { setProgress } = videoSlice.actions;
export default videoSlice.reducer;
