import React from "react";
import { Stack, Box } from "@mui/material";

import VideoCardPage from "../SearchPage/VideoCardPage";
import { useSelector } from "react-redux";
import Loader from "../Loader";

const UserPlayList = ({ videos, direction }) => {
  const {  loadingLikeVideos } = useSelector((state) => state.videos);
  console.log("Loading", loadingLikeVideos);
  // if(!videos?.length) return <Loader number={2} />;
  if(loadingLikeVideos === "pending") return <Loader />;
  
  return (
    <Stack direction={direction || "row"} flexWrap="wrap" justifyContent="start" alignItems="start" gap={2}>
      {videos?.map((item, idx) => (
        <Box key={idx}>
          {item._id && <VideoCardPage video={item} /> }
          {/* {item.id.channelId && <ChannelCard channelDetail={item} />} */}
        </Box>
      ))}
    </Stack>
  );
}

export default UserPlayList;
