// transactionSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const makeTransactionDeposit = createAsyncThunk(
  "transactions/makeTransactionDeposit",
  async (
    { transactionHash, amountBNB, amountUSDT, addressFrom, token },
    thunkAPI
  ) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/transactions/make-transaction-deposit`,
        {
          transactionHash,
          amountBNB,
          amountUSDT,
          addressFrom,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add token here in the headers
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const makePayVideo = createAsyncThunk(
  "transaction/makePayVideo",
  async ({ videoId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/transactions/make-transaction-video-balance`,
        { videoId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const transactionSlice = createSlice({
  name: "transactions",
  initialState: {
    transactionData: null,
    loadingTransactionData: "idle",
    errorTransactionData: null,
    payVideo: null,
    loadingPayVideo: "idle",
    errorPayVideo: null,
  },
  reducers: {
    clearPayVideoState: (state) => {
      state.payVideo = null;
      state.errorPayVideo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(makeTransactionDeposit.pending, (state) => {
        state.loadingTransactionData = "pending";
        state.errorTransactionData = null;
      })
      .addCase(makeTransactionDeposit.fulfilled, (state, action) => {
        state.loadingTransactionData = "succeeded";
        state.transactionData = action.payload;
      })
      .addCase(makeTransactionDeposit.rejected, (state, action) => {
        state.loadingTransactionData = "faild";
        state.errorTransactionData = action.payload;
      })
      .addCase(makePayVideo.pending, (state) => {
        state.loadingPayVideo = "pending";
        state.errorPayVideo = null;
      })
      .addCase(makePayVideo.fulfilled, (state, action) => {
        state.loadingPayVideo = "succeeded";
        state.payVideo = action.payload;
      })
      .addCase(makePayVideo.rejected, (state, action) => {
        state.loadingPayVideo = "faild";
        state.errorPayVideo = action.payload;
      });
  },
});
export const { clearPayVideoState } = transactionSlice.actions;
export default transactionSlice.reducer;
