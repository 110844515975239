import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close'; 
import { IconButton } from '@mui/material';
function AdBanner({setAdWebVisible}) {
  useEffect(() => {
    const adBanner = document.getElementById('ad-banner');

    if (!adBanner) {
      console.error('Ad banner element not found.');
      return;
    }

    // Create and insert the setup script
    const script3 = document.createElement('script');
    script3.type = 'text/javascript';
    script3.id = 'ad-setup-script'; // Add an ID to the script for easy removal
    script3.innerHTML = `
      atOptions = {
        'key' : '3c3174bf074b3cc207eff07706ddc918',
        'format' : 'iframe',
        'height' : 90,
        'width' : 728,
        'params' : {}
      };
    `;
    adBanner.appendChild(script3);

    // Create and insert the load script
    const script728x90 = document.createElement('script');
    script728x90.type = 'text/javascript';
    script728x90.id = 'ad-load-script'; // Add an ID to the script for easy removal
    script728x90.src = "//www.topcreativeformat.com/3c3174bf074b3cc207eff07706ddc918/invoke.js";
    adBanner.appendChild(script728x90);

    return () => {
      const setupScript = document.getElementById('ad-setup-script');
      const loadScript = document.getElementById('ad-load-script');
      if (setupScript && adBanner.contains(setupScript)) {
        adBanner.removeChild(setupScript);
      }
      if (loadScript && adBanner.contains(loadScript)) {
        adBanner.removeChild(loadScript);
      }
    };
  }, []);

  return (
    <div
      id="ad-banner"
      style={{
        position: 'fixed',
        bottom: '0px',
        transform: 'translateX(50%)', // Center the banner horizontally
        width: '728px', // Adjust width if needed
        height: '90px', // Adjust height if needed
        zIndex: 1000, // Ensure the banner is on top
        backgroundColor: '#f0f0f0', // Optional background color for visibility
      }}
    >
            <IconButton
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1100,
                color: '#000',
                backgroundColor: 'transparent',
                width: '24px',  // Smaller width
                height: '24px', // Smaller height
                padding: '0',   // Remove extra padding
                '& .MuiSvgIcon-root': {
                  fontSize: '18px', // Smaller icon size
                },
              }}
      onClick={()=> setAdWebVisible(false)}
            >
              <CloseIcon />
            </IconButton>
    </div>
  );
}

export default AdBanner;
