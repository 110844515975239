import React from "react";
import { Stack, Box } from "@mui/material";

import { ChannelCard, Loader } from "./";
import VideoCardPage from "./SearchPage/VideoCardPage";
import { useSelector } from "react-redux";

const Videos = ({ videos, direction }) => {
  const { videoCategory, loadingCategory, errorCategory } = useSelector((state) => state.videos);
  console.log("Loading", loadingCategory);
  if(!videos?.length) return <Loader />;
  if(loadingCategory === "pending") return <Loader />;
  
  return (
    <Stack direction={direction || "row"} flexWrap="wrap" justifyContent="start" alignItems="start" gap={2}>
      {videos.map((item, idx) => (
        <Box key={idx}>
          {item._id && <VideoCardPage video={item} /> }
          {/* {item.id.channelId && <ChannelCard channelDetail={item} />} */}
        </Box>
      ))}
    </Stack>
  );
}

export default Videos;
