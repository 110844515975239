// features/videoSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const fetchVideos = createAsyncThunk(
  'videosCreator/fetchVideos',
  async ({ shortLinkUser,token, page, limit }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/videos/shortlinkUser/${shortLinkUser}?page=${page}&limit=${limit}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`, 
            'accept': '*/*'
          }
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchVideosChannel = createAsyncThunk(
  'videosCreator/fetchVideosChannel',
  async ({ shortlink, page, limit,token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/videos/shortlinkUser/${shortlink}`,
        {
          params: { page, limit },
          headers: {
            'accept': '*/*',
            // 'Authorization': `Bearer ${token}`, // Replace with actual token
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const videoCreatorSlice = createSlice({
  name: 'videosCreator',
  initialState: {
    videosCreator: [],
    loadingvideosCreator: false,
    errorvideosCreator: null,
    videosChannel: [],
    loadingvideosChannel: "idle",
    errorvideosChannel: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideos.pending, (state) => {
        state.loadingvideosCreator = true;
        state.errorvideosCreator = null;
      })
      .addCase(fetchVideos.fulfilled, (state, action) => {
        state.loadingvideosCreator = false;
        state.videosCreator = action.payload;
      })
      .addCase(fetchVideos.rejected, (state, action) => {
        state.loadingvideosCreator = false;
        state.errorvideosCreator = action.payload;
      }).addCase(fetchVideosChannel.pending, (state) => {
        state.loadingvideosChannel = "loading";
        state.errorvideosChannel = null;
      })
      .addCase(fetchVideosChannel.fulfilled, (state, action) => {
        state.loadingvideosChannel = "succeeded";
        state.videosChannel = action.payload;
      })
      .addCase(fetchVideosChannel.rejected, (state, action) => {
        state.loadingvideosChannel = "failed";
        state.errorvideosChannel = action.payload;
      });
  },
});

export default videoCreatorSlice.reducer;
