import React from "react";
import { Stack, Box } from "@mui/material";
import Loader from "../Loader";
import VideoCard from "../VideoCard";
import ChannelCard from "../ChannelCard";
import VideoCardPage from "../SearchPage/VideoCardPage";


const VideoRelative = ({ videos, direction }) => {
  if(!videos?.length) return <Loader number={2} />;
  
  return (
    <Stack direction={direction || "row"} flexWrap="wrap" justifyContent="start" alignItems="start" gap={2}>
      {videos.map((item, idx) => (
        <Box key={idx}>
          {item._id && <VideoCardPage video={item} /> }
          {/* {item.id.channelId && <ChannelCard channelDetail={item} />} */}
        </Box>
      ))}
    </Stack>
  );
}

export default VideoRelative;