import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";

import { Videos, ChannelCard } from "./";
import { fetchFromAPI } from "../utils/fetchFromAPI";
import UserCard from "./UserCard";

const UserProfile = () => {
  

  return (
    <Box minHeight="95vh">
      <Box width={"100%"}>
        <UserCard  />
      </Box>
    </Box>
  );
};

export default UserProfile;